// ========================================= //
// Settings
// ========================================= //

// *Import Font
$fontPath: '../../media/fonts/';

@font-face {
    font-family: 'GothamBlack';
    src: url('#{$fontPath}GothamBlack/GothamBlack.eot?#iefix') format('embedded-opentype'),
         url('#{$fontPath}GothamBlack/GothamBlack.woff') format('woff'),
         url('#{$fontPath}GothamBlack/GothamBlack.ttf')  format('truetype'),
         url('#{$fontPath}GothamBlack/GothamBlack.svg#GothamBlack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamBook';
    src: url('#{$fontPath}GothamBook/GothamBook.eot?#iefix') format('embedded-opentype'),
         url('#{$fontPath}GothamBook/GothamBook.woff') format('woff'),
         url('#{$fontPath}GothamBook/GothamBook.ttf')  format('truetype'),
         url('#{$fontPath}GothamBook/GothamBook.svg#GothamBook') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamRounded-Bold';
    src: url('#{$fontPath}GothamRounded-Bold/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
         url('#{$fontPath}GothamRounded-Bold/GothamRounded-Bold.otf')  format('opentype'),
         url('#{$fontPath}GothamRounded-Bold/GothamRounded-Bold.woff') format('woff'),
         url('#{$fontPath}GothamRounded-Bold/GothamRounded-Bold.ttf')  format('truetype'),
         url('#{$fontPath}GothamRounded-Bold/GothamRounded-Bold.svg#GothamRounded-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

// *Define Font
$gotham--black: 'GothamBlack';
$gotham--book: 'GothamBook';
$gotham--bold: 'GothamRounded-Bold';

// *Define Var
$gutter                 : 0.625rem !default;    //10px
$bezier                 : cubic-bezier(.7, 0, .175, 1);
$img                    : '../../media/images/' !default;

// *Define Color
$white                  : #ffffff !default;
$error                  : #d66666 !default;
$black                  : #000 !default;
$gray                   : #dddddd !default;
$red                    : #de0022 !default;
$grayDark               : #252525 !default;
$grayDarker             : #333333 !default;

// *Define Fonts
$montserrat             : 'Montserrat', sans-serif;
$opensans               : 'Open Sans', sans-serif;

// *Define Breakpoints
$device-xsmall                 : 26.25em !default;     //420px
$device-small                  : 40em !default;        //640px
$device-xmedium                : 52.5em !default;      //840px
$device-medium                 : 81.25em !default;     //1300px
$device-large                  : 90em !default;        //1440px
